@import "reboot";
@import "variables-mixins";

// Global themes

body {
  font-family: Helvetica, Arial, sans-serif;
  color: $color-text-dark;
  background-color: $color-background;
}

a {
  color: $color-tint;
  text-decoration: none;

  &:hover {
    color: darken($color: $color-tint, $amount: 10)
  }
}

h1 {
  font-size: 1.625rem;
  font-weight: bold;
}

h2 {
  font-size: 1.25rem;
  font-weight: normal;
}

.top-error {
  padding: 0.8rem 1.5rem;
  color: white;
  background-color: $color-state-red;
}

// Page structure

body > header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 30px;
  background-color: $color-primary;

  img {
    height: 2rem;
  }

  a {
    color: rgba($color: white, $alpha: 0.6);

    &:hover {
      color: white;
    }
  }

  @include breakpoint-mobile {
    padding: 1.2rem $content-margin;

    nav {
      display: none;
    }
  }
}

body > main, body > footer {
  width: 700px;
  margin: 2rem auto;

  @include breakpoint-mobile {
    width: inherit;
    margin: 2rem $content-margin;
  }
}

body > footer {
  display: none;
  text-align: center;

  @include breakpoint-mobile {
    display: block;
  }
}

// Error page

#error-page {
  margin: 4rem 0;
  text-align: center;
}
