// Colors

$color-primary: #3D3A6A;
$color-tint: #33B29B;
$color-background: #F2F2F2;
$color-text-dark: #3E3E3E;
$color-text-light: #8B8B8B;

$color-state-green: #58C159;
$color-state-orange: #F3A11C;
$color-state-red: #E0243B;
$color-state-disabled: #7A7A7A;

$color-data-brown: #C27A5B;
$color-data-purple: #8E5DB6;
$color-data-red: #D44556;

// Sizes

$content-width: 700px;
$content-margin: 15px;

$breakpoint-mobile: $content-width + 2 * $content-margin - 1;

// Mixins

@mixin breakpoint-mobile {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}
